div.loginPage {
    .outer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
    }
    
    .inner {
        margin-top: 20px !important;
        width: 700px;
        margin: auto;
        background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
        padding: 40px 55px 45px 55px;
        border-radius: 15px;
        transition: all .3s;
    }
    
    .outer .form-control:focus {
        border-color: #167bff;
        box-shadow: none;
    }
    
    .outer h3 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
    }
    
    .custom-control-label {
        font-weight: 400;
    }

    .loginForm {
        h1, .submitButton {
            text-align: center;
        }

        div.row {
            margin: 10px;
        }
    }
}